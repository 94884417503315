<template>
  <b-modal
    ref="modalDeleteTransaction"
    v-model="isShowModal"
    centered
    @show="show"
    @hidden="hide"
  >
    <template #modal-header>
      <div class="ft-black">Delete Item</div>
    </template>
    <b-row>
      <b-col class="text-center">
        <div class="color-black ft-16"><b>Do you want to delete all entries?</b></div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click.prevent="deleteTransaction" class="btn-cancel">
            Yes
          </b-button></b-col
        >
        <b-col
          ><b-button class="btn-confirm" @click="hide"> No </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    deleteTransaction() {
      this.$emit("confirmDeleteTransaction");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-black {
  color: var(--font-color);
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
