<template>
  <b-modal
    ref="modalCenterPoint"
    centered
    @show="show"
    @hidden="hide"
    body-class="p-4"
    v-model="isShowModal"
    hide-footer
  >
    <template #modal-header>
      <div class="ft-title">Center Point</div>
    </template>
    <div>
      <b-table
        class="mb-0"
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(center_point)="data">
          <div class="status-complete">
            {{ data.item.center_point | numeral("0,0") }}
          </div>
        </template>
      </b-table>
    </div>
    <div class="text-center">
      <b-button class="mt-3 btn-confirm" @click="hide">Close</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 17px;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 50%;
}
</style>
