<template>
  <b-modal
    ref="modalVoidTransaction"
    v-model="isShowModal"
    centered
    @show="show"
    @hidden="hide"
  >
    <template #modal-header>
      <div class="ft-black">Refund</div>
    </template>
    <div>
      <div>
        <InputText
          textFloat="Ref.Refund Bill"
          placeholder="Ref.Refund Bill"
          type="number"
          name="bill_void"
          v-model="form.invoice_no"
          :v="v.formVoid.invoice_no"
          :isValidate="v.formVoid.invoice_no.$error"
          @onKeyup="onKeyupAddZero"
        />
      </div>
      <div>
        <InputTextArea
          :rows="4"
          textFloat="Additional Notes (if any)"
          placeholder="Additional Notes"
          id="textarea-rows"
          v-model="form.note"
        />
      </div>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button
            @click.prevent="VoidTransaction"
            class="btn-cancel"
            :disabled="disableBtnVoid"
          >
            Yes
          </b-button></b-col
        >
        <b-col
          ><b-button class="btn-confirm" @click="hide"> No </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    InputText,
    InputTextArea,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    disableBtnVoid: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    onKeyupAddZero(val) {
      this.form.invoice_no = ("0000000000" + this.form.invoice_no).slice(-10);
    },
    VoidTransaction() {
      this.v.formVoid.$touch();
      if (this.v.formVoid.$error) {
        return;
      }
      this.$emit("confirmVoidTransaction", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
